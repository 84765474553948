<template>
  <div>
    <v-btn color="primary" href="/admin/presentation/create">Create Presentation</v-btn>

    <br /><br />

    <v-data-table
      :headers="headers"
      :items="localPresentations"
      :options.sync="options"
      :items-per-page="-1"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:no-data>
        <div>There are no presentations</div>
      </template>
      <template v-if="localPresentations.length" v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="item.name">
            <td>{{ item.title }}</td>
            <td>
                {{ item.code }}
            </td>
            <td>
              {{ item.slides.length }}
            </td>
            <td>
              <v-btn
                :href="'/admin/presentation/' + item.id + '/edit'"
                class="mx-2"
                fab
                dark
                x-small
                color="primary"
              >
                <v-icon dark>edit</v-icon>
              </v-btn>

              <v-btn @click="deletePresentation(item)" class="mx-2" fab dark x-small color="error">
                <v-icon dark>delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ['presentations'],
  data() {
    return {
      search: null,
      localPresentations: this.presentations,
      options: { sortDesc: [true], sortBy: ['created_at'] },
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Code', value: 'code' },
        { text: 'Slides count', value: 'slides' },
        { text: 'Actions', value: 'actions', sortable: false, width: '10%' }
      ]
    };
  },
  methods: {
    deletePresentation(presentation) {
      if (confirm('Delete whole presentation? This will remove all files assigned to the slides!')) {
        let index = this.localPresentations.findIndex(x => x.id === presentation.id);

        axios.delete('/admin/presentation/' + presentation.id).then(res => {
          this.localPresentations.splice(index, 1);
        });
      }
    }
  }
};
</script>
