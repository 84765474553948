/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

window.Vue = require('vue');

import Vuetify from 'vuetify';

Vue.use(Vuetify);
const vuetify = new Vuetify();

/**
 * The following block of code may be used to automatically register your
 * Vue components recursively from the components directory.
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
const files = require.context('./', true, /\.vue$/i);
files.keys().map(key =>
  Vue.component(
    key
      .split('/')
      .pop()
      .split('.')[0],
    files(key).default
  )
);

const app = new Vue({
  el: '#app',
  vuetify: new Vuetify({ dark: false }),
  data: {
    drawer: false, // controls the navigation-drawer
    fluid: true
  },
  methods: {
    logout() {
      document.getElementById('logout-form').submit();
    }
  }
});
