<template>
  <div>
    <v-menu
      :close-on-content-click="true"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>format_align_center</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          :class="{ 'is-active': isActive(item.value) }"
          v-for="(item, index) in alignments" :key="index"
          @click.native="onSelect(item.value)"
        >
          <v-list-item-title>
            <v-icon>format_align_{{item.value}}</v-icon>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { isNodeActive } from '../../utils/node'
export default {
  name: 'align-dropdown',
  data () {
    return {
    }
  },
  props: {
    editor: {
      type: Object
    }
  },
  methods: {
    isActive (alignment) {
      return isNodeActive(this.editor.state, 'textAlign', alignment)
    },
    onSelect(value) {
      this.editor.commands.alignment({textAlign: value});
    }
  },
  mounted() {
    console.log(this.editor.commands);
  },
  computed: {
    alignments () {
      return [
        { label: 'Left', value: 'left'},
        { label: 'Center', value: 'center'},
        { label: 'Right', value: 'right'},
        { label: 'Justify', value: 'justify'},
      ]
    }
  }
}
</script>

<style lang="scss">
.align-dropdown {
  padding: 4px;
}
</style>
