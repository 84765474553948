<template>
  <div class="container-fluid">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar color="success" top v-model="snackbar" :timeout="1500">
      Presentation Updated!
      <v-icon dark>check_circle</v-icon>
    </v-snackbar>

    <v-snackbar color="red" top v-model="updateNotesSnackbar" :timeout="1500">
      Please describe the changed you've made.
      <v-icon dark>highlight_off</v-icon>
    </v-snackbar>

    <v-row>
      <v-col md="6">
        <div class="display-1">
          <template v-if="existingPresentation">
            Edit: {{ existingPresentation.title }}
          </template>
          <template v-else>
            Create Presentation
          </template>
          <v-icon>school</v-icon>
        </div>

        <v-divider></v-divider>
      </v-col>

      <v-col md="6">
        <v-btn class="float-right" href="/admin">Back</v-btn>
      </v-col>
    </v-row>
    <br />

    <v-row>
      <v-col md="8">
        <v-card color="blue lighten-5" v-show="showGuide">
          <v-card-title class="headline">Guide</v-card-title>
          <v-card-text style="color: #111">
            <ol>
              <li>If uploaded files have names ex: 1.jpg, 2.jpg... upload will retain that order only in case there are no existing slides in presentation.</li>
              <li>If presentation already have slides next order of slide will be automatically calculated.</li>
              <li>You can change the order of slides by dragging and dropping slide to the desired position.</li>
            </ol>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col md="4">
        <v-btn class="float-right" color="primary" @click="showGuide = !showGuide">
          <v-icon v-if="showGuide">close</v-icon>
          <v-icon v-else>info</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-col md="12">
      <v-alert type="error" v-if="errorBag.first('general')">
        {{ errorBag.first('general') }}
      </v-alert>
    </v-col>

    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-text-field
            label="Title"
            :readonly="presentation.id !== undefined"
            placeholder="Enter the title of the presentation"
            v-model="presentation.title"
            :rules="[v => !!v || 'You must enter the title']"
            :error-messages="errorBag.first('title')"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="presentation.id !== undefined">
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-textarea
                  label="Update Notes - will be shown to users in the desktop application"
                  placeholder="Describe the updates"
                  v-model="update_notes"
                  :rules="[v => !!v || 'You must describe the updates']"
                  :error-messages="errorBag.first('update_notes')"
                  outlined
          ></v-textarea>
        </v-col>
      </v-row>

      <v-col cols="12" md="12">
        <vue-dropzone ref="presentationDropzone" id="dropzone" :options="dropzoneOptions"
                      @vdropzone-file-added="fileAdded"
                      @vdropzone-removed-file="removeFile"
                      @vdropzone-error="fileError()"></vue-dropzone>
      </v-col>

      <v-row justify="center">
        <v-col md="3" sm="8" xs="12">
          <v-btn color="primary" block @click="submit">Add slides</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <slidesEditor v-if="slides.length"
                  :existing-slides="slides"
                  :presentation-id="presentation.id"
                  @activateOverlay="overlay = true"
                  @deactivateOverlay="overlay = false"
    ></slidesEditor>
  </div>
</template>

<script>
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import ErrorBag from '../ErrorBag';

export default {
  props: ['existingPresentation', 'existingSlides'],
  components: {
    vueDropzone: vue2Dropzone
  },
  mounted() {
    if (this.existingPresentation) {
      this.presentation = this.existingPresentation;
      this.slides = this.existingSlides;
    }
  },
  data() {
    return {
      update_notes: null,
      errorBag: new ErrorBag(),
      valid: false,
      showGuide: false,
      overlay: false,
      snackbar: false,
      updateNotesSnackbar: false,
      files: [],
      filesCounter: 0,
      slides: [],
      presentation: {
        title: null,
      },
      dropzoneOptions: {
        url: '/admin/presentation/create_slides',
        maxFilesize: 256,
        maxFiles: 150,
        addRemoveLinks: true,
        headers: {"X-CSRF-TOKEN": window.Laravel.csrfToken},
        vueInstance: this,
        autoProcessQueue: false,
        autoQueue: false,
        uploadMultiple: true
      },
    };
  },
  methods: {
    removeFile(file, error, xhr) {
      let fileIndex = this.files.findIndex(x => x.dataURL === file.dataURL);

      if (fileIndex > -1) {
        this.files.splice(fileIndex, 1);
      }
    },
    fileError(file, message, xhr) {
      console.log(message);
      console.log(xhr);
      alert('Something seriously went wrong. Contact Developers!');
    },
    fileAdded(file) {
      this.files.push(file);
    },
    submit() {
      let valid = this.$refs.form.validate();

      if (valid) {
        this.overlay = true;
        let url = this.existingPresentation ? '/admin/presentation/' + this.presentation.id : '/admin/presentation';
        let method = this.existingPresentation ? 'put' : 'post';

        let formData = new FormData();
        formData.append('title', this.presentation.title);
        formData.append('update_notes', this.update_notes);

        this.files.forEach(file => {
          formData.append('files[]', file);
        });

        formData.append('_method', method);

        axios
          .post(url, formData)
          .then(res => {
            this.overlay = false;
            this.errorBag.clearAll();

            if (!this.existingPresentation) {
              window.location = '/admin/presentation/' + res.data.presentation.id + '/edit';
            } else {
              this.snackbar = true;

              this.presentation = Object.assign({}, res.data.presentation);
              this.slides = res.data.slides;

              this.files.forEach(file => {
                file.previewElement.parentNode.removeChild(file.previewElement);
              });

              this.files = [];
            }
          })
          .catch(err => {
            if (err.response.data.errors) {
              this.errorBag.setErrors(err.response.data.errors);
            } else if (err.response.data.message) {
              this.errorBag.setErrors({ general: err.response.data.message });
            }
            this.overlay = false;
            window.scrollTo(0, 0);
          });
      } else {
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>

<style lang="scss">
  .dz-progress {
    /* progress bar covers file name */
    display: none !important;
  }
</style>
