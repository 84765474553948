<template>

  <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
    <div class="menubar">
      <template v-for="(btn) in btns">
        <v-btn
          icon
          small
          active-class=""
          :class="{
            'v-btn--active': callFunc(isActive, btn.click),
          }"
          @click="btn.hasModal ? showLinkDialog(getMarkAttrs(btn.click.func)) : callFunc(commands, btn.click);"
        >
          <v-icon>{{ btn.icon }}</v-icon>
        </v-btn>
      </template>

      <template v-for="component in components">
        <component
          :name="component.name"
          :is="component.name"
          :editor="editor"
        />
      </template>

      <v-dialog v-model="linkMenuIsActive">
        <v-card>
          <v-card-title>Insert link</v-card-title>

          <v-card-text>
            <v-text-field
              placeholder="https://"
              solo
              dense
              v-model="linkUrl"
              ref="linkInput"
              @keydown.esc="hideLinkMenu"
              @keydown.enter="setLinkUrl(commands.link, null)"
            ></v-text-field>
            <v-btn
              @click="setLinkUrl(commands.link, null)"
            >
              Save
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </editor-menu-bar>
</template>

<script>
import { Editor, EditorMenuBar } from 'tiptap';
import BackColorDropdown from './components/buttons/BackColorDropdown';
import AlignDropdown from './components/buttons/AlignDropdown';
export default {
  name: 'EditorHeader',
  props: {
    editor: {
      type: Object,
    },
  },
  components: {
    Editor,
    EditorMenuBar,
    BackColorDropdown,
    AlignDropdown
  },
  data() {
    return {
      linkUrl: null,
      linkMenuIsActive: false,
      components: [
        {
          name: 'back-color-dropdown'
        },
        {
          name: 'align-dropdown'
        }
      ],
      btns: [
        {
          click: {
            func: 'heading',
            param: {level: 1},
          },
          icon: 'mdi-format-header-1',
        },
        {
          click: {
            func: 'heading',
            param: {level: 2},
          },
          icon: 'mdi-format-header-2',
        },
        {
          click: {
            func: 'heading',
            param: {level: 3},
          },
          icon: 'mdi-format-header-3',
        },
        {
          click: {
            func: 'bold',
            param: null,
          },
          icon: 'mdi-format-bold',
        },
        {
          click: {
            func: 'italic',
            param: null,
          },
          icon: 'mdi-format-italic',
        },
        {
          click: {
            func: 'strike',
            param: null,
          },
          icon: 'mdi-format-strikethrough',
        },
        {
          click: {
            func: 'underline',
            param: null,
          },
          icon: 'mdi-format-underline',
        },
        {
          hasModal: true,
          click: {
            func: 'link',
            param: null,
          },
          icon: 'mdi-link',
        }
      ]
    };
  },
  methods: {
    callFunc(func, click) {
      if (func != null && click != null && click.func != null) {
        if (func[click.func]) {
          if (click.param) {
            return func[click.func](click.param);
          } else {
            return func[click.func]();
          }
        }
      }
    },
    showLinkDialog(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url ?? this.linkUrl })
      this.hideLinkMenu()
    },
  },
};
</script>

<style lang="scss">
.menubar {
  display: flex;
  height: auto !important;
  padding: 10px 5px;
  background: #fafafa;

  button {
    height: auto !important;
    flex-wrap: wrap;
  }
}
</style>
