<template>
  <div id="slides-editor">
    <draggable
      :list="slides"
      class="row pt-5 dragger"
      ghost-class="ghost"
      @start="dragging = true"
      @end="slideMoved"
    >
      <v-col sm="6" md="4" xs="12" v-for="(slide, index) in slides" :key="slide.order" style="position: relative"
             class="d-flex flex-column">
        <v-card class="flex d-flex flex-column">
          <v-badge
            :content="slide.order"
            :value="slide.order"
            color="primary"
            overlap
            class="order-number"
          >
          </v-badge>

          <div class="image-holder" v-if="slide.type === 'image'">
            <img :src="`/files/thumbnails/${slide.source}`" alt="Please reupload image">
          </div>
          <div class="image-holder" v-else>
            <video id="video" :src="`/files/${slide.source}`" controls="false"></video>
          </div>

          <v-card-text class="flex">
            <v-form ref="form" v-model="valid" v-show="! dragging" @submit.prevent="false">

              <v-col cols="12" md="12">
                <v-file-input v-model="slide.file" label="Change image/video"></v-file-input>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Table of contents heading"
                  placeholder="Enter table of contents heading"
                  v-model="slide.heading"
                  :error-messages="errorBag.first('heading')"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <label>Notes</label>
                <editor
                  v-model="slide.notes"
                  required
                  placeholder="Notes"
                />
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="deleteSlide(slide, index)"
            >
              <v-icon
                color="error">delete
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </draggable>

    <v-row class="save-all">
      <v-col md="6" offset="3">
        <v-btn @click="saveAllSlides()" x-large center block color="primary">Save all slides</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ErrorBag from '../ErrorBag';
import draggable from 'vuedraggable';
import Editor from './TipTap/Editor';

export default {
  props: ['existingSlides', 'presentationId'],
  components: {
    draggable,
    Editor,
  },
  mounted() {
    if (this.existingSlides.length) {
      this.slides = this.existingSlides;
    }
  },
  data() {
    return {
      errorBag: new ErrorBag(),
      valid: false,
      snackbar: false,
      slides: [],
      dragging: false,
    };
  },
  methods: {
    slideMoved() {
      this.dragging = false;
      this.slides.map(function (slide, index) {
        slide.order = index + 1;
      });

      axios.put('/admin/slide/update-order', {
        slides: this.slides,
        presentationId: this.presentationId,
      });
    },
    deleteSlide(slide, index) {
      if (confirm('Delete this slide')) {
        this.$emit('activateOverlay');
        axios.delete('/admin/slide/' + slide.id).then(res => {
          let slides = res.data.slides;
          this.slides = slides.slice(0, slides.length);
          this.$emit('deactivateOverlay');
        });

      }
    },
    saveAllSlides()
    {
      let valid = this.$parent.$refs.form.validate();

      if (! valid || ! this.$parent.$data.update_notes) {
        this.$parent.$data.updateNotesSnackbar = true;
        return
      }

      let formData = new FormData();

      this.slides.forEach(slide => {
        formData.append('slides['+slide.id+'][heading]', slide.heading != 'null' ? slide.heading : '');
        formData.append('slides['+slide.id+'][notes]', slide.notes != 'null' ? slide.notes : '');

        if (slide.file) {
          formData.append('slides['+slide.id+'][file]', slide.file);
        }
      });
      formData.append('update_notes', this.$parent.$data.update_notes);
      formData.append('_method', 'put');
      axios
        .post('/admin/slide/'+this.presentationId+'/save-all', formData)
        .then(res => {
          this.errorBag.clearAll();
          let slides = res.data.slides;

          this.slides = slides.slice(0, slides.length);
          this.$emit('deactivateOverlay');
          this.$parent.$data.update_notes = null;
          this.$parent.$data.snackbar = true;
        })
        .catch(err => {
          if (err.response.data.errors) {
            this.errorBag.setErrors(err.response.data.errors);
          } else if (err.response.data.message) {
            this.errorBag.setErrors({general: err.response.data.message});
          }
        });

    },
    saveSlide(slide, index) {
      slide.overlay = true;
      this.slides.splice(index, 1, slide);

      let formData = new FormData();

      formData.append('heading', slide.heading);
      formData.append('notes', slide.notes);
      formData.append('_method', 'put');

      if (slide.file) {
        formData.append('file', slide.file);
      }

      axios
        .post('/admin/slide/' + slide.id, formData)
        .then(res => {
          this.errorBag.clearAll();
          let respondedSlide = res.data.slide;
          respondedSlide.overlay = false;
          respondedSlide.heading = respondedSlide.heading != 'null' ? respondedSlide.heading : '';

          this.slides.splice(index, 1, res.data.slide);
        })
        .catch(err => {
          if (err.response.data.errors) {
            this.errorBag.setErrors(err.response.data.errors);
          } else if (err.response.data.message) {
            this.errorBag.setErrors({general: err.response.data.message});
          }
        });
    }
  },
  watch: {
    existingSlides: {
      handler(slides, oldValue) {
        this.slides = slides;
      },
      deep: true
    },
  }
};
</script>

<style lang="scss">
.order-number {
  position: absolute;
  left: 0;
  top: 0;
}

.tiptap-vuetify-editor {
  cursor: text;
}

.dragger {
  cursor: move;
}

.image-holder {
  text-align: center;
  padding: 20px;
  border: 1px solid #dcdcdc;

  img, video {
    max-width: 100%;
  }
}
#slides-editor {
  padding-bottom: 100px;
}
.save-all {
  position: fixed;
  bottom: 10px;
  width: 100%;
}

</style>
