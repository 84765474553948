<template>
  <div>
    <v-menu
      :close-on-content-click="true"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-format-color-text</v-icon>
        </v-btn>
      </template>
      <section class="row justify-center label" slot="label">
        <div>
          <v-icon>mdi-format-color-highlight</v-icon>
        </div>
        <div class="indicator" :style="`background: ${hex}`"></div>
      </section>
      <color-board :active-color="activeColor" @select="onSelect"/>
    </v-menu>
  </div>
</template>

<script>
import ColorBoard from '../common/ColorBoard';
import { findActiveMarkAttribute } from '../../utils/mark';

export default {
  name: 'back-color-dropdown',
  data() {
    return {
      hex: '#ffec3d',
      closable: false
    };
  },
  props: {
    editor: {
      type: Object
    }
  },
  components: {
    ColorBoard
  },
  methods: {
    onSelectCurrent() {
      this.onSelect(this.hex);
    },
    onSelect(color) {
      this.closable = true;
      this.hex = color;
      this.editor.commands.backColor({backColor: color});
    }
  },
  computed: {
    activeColor() {
      return findActiveMarkAttribute(this.editor.state, 'backColor');
    }
  }
};
</script>

<style lang="scss">
.o-back-color-dropdown {
  .q-btn-dropdown__arrow {
    margin-left: 0;
  }
  .q-btn {
    &:first-child {
      padding: 0 2px 0 4px;
    }
    &:last-child {
      padding: 0 0px 0 2px;
    }
  }
  .q-btn-dropdown__arrow-container {
    border: none;
  }
  .label {
    width: 20px;
    height: 20px;
    margin-top: -2px;
    .q-icon {
      margin-top: -8px;
      font-size: 1.2rem;
    }
    .indicator {
      width: 14px;
      height: 3px;
      margin-top: -8px;
    }
  }
}
.o-back-color-menu {
  .q-color-picker {
    min-width: 250px;
    max-width: 280px;
  }
  .q-color-picker__palette-rows {
    padding: 8px;
  }
  .q-color-picker__cube {
    margin: 3px;
    width: calc(10% - 6px) !important;
    padding-bottom: calc(10% - 6px) !important;
    border-radius: 2px;
    &:hover {
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    }
  }
}

</style>
