<template>
  <div>
    <v-card class="editor-content" @click="editor.focus();">
      <editor-header :editor="editor"></editor-header>

      <editor-content :editor="editor" />
    </v-card>
  </div>
</template>

<script>
import { Editor, EditorContent } from 'tiptap';
import EditorHeader from './EditorHeader';
import BackColor from './extensions/BackColor';
import Align from './extensions/Align';
import Alignment from './extensions/Alignment';
import Paragraph from './extensions/Paragraph';
import {
  Heading,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  TrailingNode
} from 'tiptap-extensions';

export default {
  name: 'Editor',
  props: ["value"],
  components: {
    EditorContent,
    EditorHeader,
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        new TrailingNode({
          node: 'paragraph',
          notAfter: ['paragraph']
        }),
        new Heading({levels: [1, 2, 3]}),
        new Bold(),
        new Code(),
        new Italic(),
        new Link(),
        new Strike(),
        new Underline(),
        new BackColor(),
        new Align(),
        new Paragraph(),
        new Alignment(),
      ],
      content: this.value,
      onUpdate: ({ getHTML }) => {
        console.log(getHTML());
        this.$emit('input', getHTML())
      },
    });

    this.editor.setContent(this.value)
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  watch: {
    value (val) {
      // so cursor doesn't jump to start on typing
      if (this.editor && val !== this.value) {
        this.editor.setContent(val, true)
      }
    }
  }
};
</script>

<style>
.editor-content .ProseMirror {
  text-align: left;
  padding: 10px 10px 100px 10px;
}

.editor-content table td,
.editor-content table th {
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.editor-content table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}

.editor-content .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.v-ripple__container {
  display:none !important;
}

:focus {
  outline: none;
}
</style>
